import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAllMatchesOddsCricketAPI,
  getBetCountAPI,
} from "../../service/cricket";
import moment from "moment";

export default function AllMatchModal({ show, setShow }) {
  const selectedGame = "cricket";
  const dispatch = useDispatch();
  const [cricketData, setCricketData] = React.useState([]);
  const getBetCount = async () => {
    const { response } = await getBetCountAPI();
    return response;
  };
  const getCricketMatches = async () => {
    try {
      const { response, code } = await getAllMatchesOddsCricketAPI();
      if (code == 200) {
        let counts = await getBetCount();
        response.map((item) => {
          let countData =
            counts?.betCount[
              Object.keys(counts.betCount).find(
                (count) => count == item?.cricketId
              )
            ];

          item.matchCount = countData?.matchCount;
          item.fancyCount = countData?.fancyCount;
          return item;
        });

        dispatch({
          type: "gameData/setCricketGames",
          payload: response,
        });
        setCricketData(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let interval;
    getCricketMatches();
    if (selectedGame == "cricket") {
      getCricketMatches();
      interval = setInterval(() => {
        getCricketMatches();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedGame]);
  console.log("cricketData", cricketData);
  return (
    <div className="ant-modal-root">
      <div className="ant-modal-mask" />
      <div tabIndex={-1} className="ant-modal-wrap">
        <div
          role="dialog"
          aria-labelledby="rc_unique_0"
          aria-modal="true"
          className="ant-modal gx-px-3 "
          style={{ width: 520, transformOrigin: "254px 499px" }}
        >
          <div
            tabIndex={0}
            aria-hidden="true"
            style={{
              width: 0,
              height: 0,
              overflow: "hidden",
              outline: "none",
            }}
          />
          <div className="ant-modal-content">
            <button
              type="button"
              aria-label="Close"
              onClick={() => setShow(false)}
              className="ant-modal-close"
            >
              <span className="ant-modal-close-x">
                <span
                  role="img"
                  aria-label="close"
                  className="anticon anticon-close ant-modal-close-icon"
                >
                  <svg
                    fillRule="evenodd"
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="close"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
                  </svg>
                </span>
              </span>
            </button>
            <div className="ant-modal-header">
              <div className="ant-modal-title" id="rc_unique_0">
                ALL MATCHES
              </div>
            </div>
            <main className="ant-layout-content gx-layout-content   ">
              <div
                className="gx-main-content-wrapper"
                style={{ paddingBottom: 220, height: "100vh" }}
              >
                <div className="ant-row ant-row-center">
                  <div className="ant-col ant-col-xs-24 ant-col-md-19">
                    {cricketData
                      ?.filter((i) => i.inPlay)
                      ?.map((item) => (
                        <a href={`/main/match-deatils/${item?.cricketId}`}>
                          <div className="ant-row ant-row-center gx-bg-flex gx-px-2 gx-py-3  gx-box-shadow gx-align-items-center gx-justify-content-start">
                            <div className="ant-col ant-col-lg-5">
                              <div className="gx-fs-md gx-d-none gx-d-lg-block gx-font-weight-semi-bold gx-pb-1 gx-text-black">
                                {item?.eventName}
                              </div>
                              <div
                                className="gx-px-1  gx-bg-grey responsive-text  gx-text-white gx-py-2"
                                style={{
                                  borderTopRightRadius: 100,
                                  borderBottomRightRadius: 100,
                                }}
                              >
                                <div className="gx-text-truncate gx-my-1 gx-font-weight-semi-bold ">
                                  {item?.eventName}
                                </div>
                                <div className="">
                                  {moment(item?.eventTime).format(
                                    "DD-MM-YYYY HH:mm A"
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="ant-col gx-bg-flex gx-px-2 gx-align-items-center gx-justify-content-center ant-col-lg-4 ant-col-xl-5">
                              {item?.inPlay && (
                                <div className="newBlinking gx-mx-1 " />
                              )}

                              <span className="gx-mx-1 gx-text-primary gx-fs-lg">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth={0}
                                  viewBox="0 0 256 256"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M216,60H157l27.52-27.52a12,12,0,0,0-17-17L128,55,88.49,15.51a12,12,0,0,0-17,17L99,60H40A20,20,0,0,0,20,80V200a20,20,0,0,0,20,20H216a20,20,0,0,0,20-20V80A20,20,0,0,0,216,60Zm-4,136H44V84H212Z" />
                                </svg>
                              </span>

                              <span className="gx-mx-1 gx-text-primary gx-font-italic gx-fs-lg">
                                B
                              </span>
                              <span className="gx-mx-1 gx-text-primary gx-font-italic gx-fs-lg">
                                F
                              </span>
                            </div>
                            <div className="ant-col gx-bg-flex gx-my-1 ant-col-lg-12">
                              <div
                                className="inplayElement matchdtailsYesBackground"
                                style={{ width: "9rem", gap: 3 }}
                              >
                                {item?.back1}
                              </div>
                              <div
                                className="inplayElement matchdtailsNoBackground"
                                style={{ width: "9rem", gap: 3 }}
                              >
                                {item?.lay1}
                              </div>
                              <div
                                className="inplayElement matchdtailsYesBackground"
                                style={{ width: "9rem", gap: 3 }}
                              >
                                {item?.back11}
                              </div>
                              <div
                                className="inplayElement matchdtailsNoBackground"
                                style={{ width: "9rem", gap: 3 }}
                              >
                                {item?.lay11}
                              </div>
                              <div
                                className="inplayElement matchdtailsYesBackground"
                                style={{ width: "9rem", gap: 3 }}
                              >
                                {item?.back12}
                              </div>
                              <div
                                className="inplayElement matchdtailsNoBackground"
                                style={{ width: "9rem", gap: 3 }}
                              >
                                {item?.lay12}
                              </div>
                            </div>
                            <div className="gx-fs-md gx-px-2 gx-d-lg-none gx-d-block gx-font-weight-semi-bold gx-pb-1">
                              {item?.eventName}
                            </div>
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </main>
            <div className="ant-modal-footer">
              <button
                type="button"
                onClick={() => setShow(false)}
                className="ant-btn ant-btn-default gx-bg-grey gx-text-white gx-border-redius0"
              >
                <span>Close</span>
              </button>
            </div>
          </div>
          <div
            tabIndex={0}
            aria-hidden="true"
            style={{
              width: 0,
              height: 0,
              overflow: "hidden",
              outline: "none",
            }}
          />
        </div>
      </div>
    </div>
  );
}
