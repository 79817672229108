import React from "react";
import { getCurrentBetsAPI } from "../service/AuthService";
import moment from "moment";

export default function PendingBets() {
  const [data, setData] = React.useState([]);
  const getCurrentBets = async () => {
    const { response, code } = await getCurrentBetsAPI({
      betStatus: "MATCHED",
      betType: "ALL",
      pageNumber: 0,
      pageSize: 100,
      search: "",
      sports: true,
    });
    setData(response?.unsettledBets?.content);
  };
  React.useEffect(() => {
    getCurrentBets();
  }, []);
  return (
    <main className="ant-layout-content gx-layout-content   ">
      <div className="gx-main-content-wrapper" style={{ paddingBottom: 120 }}>
        <div className="ant-row ant-row-center">
          <div className="ant-col ant-col-xs-24 ant-col-lg-18">
            <div className="background-color text-sm white-text text-center gx-mb-1 font-extrabold flex justify-between items-center">
              <div className="gx-w-100 gx-text-center gx-bg-grey gx-text-uppercase gx-text-white gx-fs-lg gx-py-1 gx-font-weight-semi-bold ">
                Pending BETS
              </div>
            </div>
            <div className="w-full overflow-x-auto scrollbar-hide">
              <div className="ant-table-wrapper text-sm">
                <div className="ant-spin-nested-loading">
                  <div className="ant-spin-container">
                    <div className="ant-table ant-table-small ant-table-bordered ant-table-empty ant-table-ping-left ant-table-ping-right ant-table-scroll-horizontal">
                      <div className="ant-table-container">
                        <div
                          className="ant-table-content"
                          style={{ overflow: "auto hidden" }}
                        >
                          <table>
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">Team</th>
                                <th
                                  className="ant-table-cell"
                                  style={{ textAlign: "center" }}
                                >
                                  Date
                                </th>
                                <th
                                  className="ant-table-cell"
                                  style={{ textAlign: "center" }}
                                >
                                  Rate
                                </th>
                                <th
                                  className="ant-table-cell"
                                  style={{ textAlign: "center" }}
                                >
                                  AMT
                                </th>
                                <th
                                  className="ant-table-cell"
                                  style={{ textAlign: "center" }}
                                >
                                  MODE
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((item) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      item.back_rate || item.back_run
                                        ? "lightblue"
                                        : "rgba(255, 0, 0, 0.2)",
                                  }}
                                  data-row-key="66fbf5ceb806a55a63c864db-0"
                                  className="ant-table-row ant-table-row-level-0"
                                >
                                  <td
                                    className="ant-table-cell llllll "
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    {item.runner_name}
                                  </td>
                                  <td
                                    className="ant-table-cell llllll "
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    {moment(item.created_date + "Z").format(
                                      "DD-MM-YYYY HH:mm A"
                                    )}
                                  </td>
                                  <td
                                    className="ant-table-cell llllll "
                                    style={{
                                      textAlign: "center",
                                      color: "black",
                                    }}
                                  >
                                    {item.back_rate
                                      ? item?.back_rate
                                      : item?.lay_rate}
                                  </td>
                                  <td
                                    className="ant-table-cell llllll "
                                    style={{
                                      textAlign: "center",
                                      color: "black",
                                    }}
                                  >
                                    {item.amount}
                                  </td>
                                  <td
                                    className="ant-table-cell llllll "
                                    style={{
                                      textAlign: "center",
                                      color: "black",
                                    }}
                                  >
                                    {item.back_rate || item.back_run
                                      ? "Back"
                                      : "Lay"}
                                  </td>
                                </tr>
                              ))}
                              {data?.length == 0 && (
                                <div
                                  className="ant-table-expanded-row-fixed"
                                  style={{
                                    width: 360,
                                    position: "sticky",
                                    left: 0,
                                    overflow: "hidden",
                                  }}
                                >
                                  <div className="gx-text-center gx-text-black">
                                    No data found
                                  </div>
                                </div>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="background-color text-sm white-text text-center gx-my-1 gx-py-4  font-extrabold flex justify-between items-center">
              <a className="" href="/main/matches">
                <div className="gx-w-100 gx-text-center gx-bg-grey gx-text-uppercase gx-text-white gx-fs-lg gx-py-1 gx-font-weight-semi-bold ">
                  BACK TO LIST
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
