import moment from "moment";
import React from "react";

export default function CompleteBetModal({
  data,
  setShow,
  show,
  fancyPlusMinus,
}) {
  return (
    <div className="ant-modal-root">
      <div className="ant-modal-mask" />
      <div tabIndex={-1} className="ant-modal-wrap">
        <div
          role="dialog"
          aria-labelledby="rc_unique_0"
          aria-modal="true"
          className="ant-modal gx-px-3 "
          style={{ width: 520, transformOrigin: "254px 499px" }}
        >
          <div
            tabIndex={0}
            aria-hidden="true"
            style={{
              width: 0,
              height: 0,
              overflow: "hidden",
              outline: "none",
            }}
          />
          <div className="ant-modal-content">
            <button
              type="button"
              aria-label="Close"
              onClick={() => setShow(false)}
              className="ant-modal-close"
            >
              <span className="ant-modal-close-x">
                <span
                  role="img"
                  aria-label="close"
                  className="anticon anticon-close ant-modal-close-icon"
                >
                  <svg
                    fillRule="evenodd"
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="close"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
                  </svg>
                </span>
              </span>
            </button>
            <div className="ant-modal-header">
              <div className="ant-modal-title" id="rc_unique_0">
                COMPLETED FANCY BET
              </div>
            </div>
            <div className="ant-modal-body">
              <div className="gx-bg-flex gx-px-2 gx-justify-content-between gx-bg-grey gx-fs-lg gx-font-weight-semi-bold gx-text-white gx-py-1">
                <div>COMPLETED FANCY BETS</div>{" "}
                <div
                  className={`gx-text-${
                    fancyPlusMinus > 0 ? "green" : "red"
                  }`}
                >
                  {fancyPlusMinus}
                </div>
              </div>
              <div className="ant-table-wrapper gx-w-100 gx-mx-0 gx-my-0">
                <div className="ant-spin-nested-loading">
                  <div className="ant-spin-container">
                    <div className="ant-table ant-table-small ant-table-bordered ant-table-empty ant-table-ping-right ant-table-scroll-horizontal">
                      <div className="ant-table-container">
                        <div
                          className="ant-table-content"
                          style={{ overflow: "auto hidden" }}
                        >
                          <table
                            style={{
                              width: "auto",
                              minWidth: "100%",
                              tableLayout: "auto",
                            }}
                          >
                            <colgroup />
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">RUNNER</th>
                                <th className="ant-table-cell">AMOUNT</th>
                                <th className="ant-table-cell">RUN</th>
                                <th className="ant-table-cell">RATE</th>
                                <th className="ant-table-cell">MODE</th>
                                <th className="ant-table-cell">DATE</th>
                                <th className="ant-table-cell">RESULTS</th>
                                <th className="ant-table-cell">P&amp;L</th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              {data.map((item, index) => (
                                <tr key={index}>
                                  <td className="ant-table-cell">
                                    {item.runnerName}
                                  </td>
                                  <td className="ant-table-cell">
                                    {item.amount}
                                  </td>
                                  <td className="ant-table-cell">
                                    {item.back || item.lay}
                                  </td>
                                  <td className="ant-table-cell">
                                    {item.backRate || item.layRate}
                                  </td>
                                  <td className="ant-table-cell">
                                    {item.type == "BACK" ? "YES" : "NO"}
                                  </td>
                                  <td className="ant-table-cell">
                                    {moment(item.createdAt + "Z").format(
                                      "DD/MM/YYYY hh:mm:ss"
                                    )}
                                  </td>
                                  <td className="ant-table-cell">
                                    {item.result}
                                  </td>
                                  <td className="ant-table-cell">
                                    {item.profit}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-modal-footer">
              <button
                type="button"
                onClick={() => setShow(false)}
                className="ant-btn ant-btn-default gx-bg-grey gx-text-white gx-border-redius0"
              >
                <span>Close</span>
              </button>
            </div>
          </div>
          <div
            tabIndex={0}
            aria-hidden="true"
            style={{
              width: 0,
              height: 0,
              overflow: "hidden",
              outline: "none",
            }}
          />
        </div>
      </div>
    </div>
  );
}
