import { AXIOS_INSTANCE } from ".";
import { BASE_URL, getToken } from "../utils/constants";

export const getUserDataAPI = async (userId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/accmanage/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAccountStatementAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/accmanage/account-log`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
