import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMatchesOddsCricketAPI,
  getBetCountAPI,
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
  getMatchDetailsCricketAPI,
  getMyBetsCricketAPI,
  placeCricketBetAPI,
  placeFancyCricketBetAPI,
} from "../service/cricket";
import { applyFancyFor, getProfit } from "../utils/constants";
import {
  getButtonValuesAPI,
  getLiveStreamingUrlAPI,
  getSportsScoreAPI,
} from "../service/AuthService";
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
  cricketMatchOddsPL,
} from "../utils/profitLoss";
import toast from "react-hot-toast";

import { useQuery } from "react-query";
// import { Spinner } from "@chakra-ui/react";
import Modal from "react-bootstrap/Modal";

import axios from "axios";
import PlaceBetModal from "./components/PlaceBet";
import CompleteBetModal from "./components/CompleteBetModal";
import { CircularProgress } from "@chakra-ui/react";
import Loader from "./components/Loader";
import AllMatchModal from "./components/AllMatchModal";

export default function BetPlay() {
  const [fancyPlusMinus, setFancyPlusMinus] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showCompleteBets, setShowCompleteBets] = useState(false);
  const [titleInfo, setInfoTitle] = React.useState("");
  const handleOpenInfoModal = (title) => {
    setOpen(true);
    setInfoTitle(title);
  };
  const [BetModalopen, setBetModalOpen] = React.useState(false);
  const handleCloseInfoModal = () => setOpen(false);
  const [showDiv, setShowDiv] = useState(false);
  const [showCompleteBet, setShowCompleteBet] = useState(false);

  const betPlaceRefMobile = useRef(null);
  const [showTv, setShowTv] = useState(false);
  const [fallOfWicketsOdds, setFallOfWicketsOdds] = useState([]);
  const [numericOdds, setNumericOdds] = useState([]);
  const [firstWicketOdds, setFirstWicketOdds] = useState([]);
  const [otherOdds, setOtherOdds] = useState([]);
  const [boundariesOdds, setBoundariesOdds] = useState([]);
  const [tvInnerHtml, setTvInnerHtml] = useState("");
  const [showTimer, setShowTimer] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(5);
  const [channelId, setChannelId] = useState(null);
  const [fullScore, setFullScore] = useState(false);
  const handleBetModalClose = () => {
    setBetModalOpen(false);
  };

  const handleDefaultValueClick = (value, toAppend = false) => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        ...selectedEventForBet,
        amount: toAppend
          ? String(
              selectedEventForBet?.amount ? selectedEventForBet.amount : ""
            ) + String(value)
          : value,
        profit: getProfit(value, game, selectedEventForBet).profit,
        loss: getProfit(value, game, selectedEventForBet).loss,
      },
    });
  };

  useEffect(() => {
    //handle betplace outside click
    const handleClickOutside = (event) => {
      if (
        betPlaceRefMobile.current &&
        !betPlaceRefMobile.current.contains(event.target)
      ) {
        dispatch({
          type: "gameData/setSelectedEventForBet",
          payload: null,
        });
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [betPlaceRefMobile]);
  const [oddQuery, setOddQuery] = useState();

  var { id } = useParams();
  const location = useLocation();
  const [showMatches, setShowMatches] = useState(false);
  var game = "cricket";
  const [gameEvent, setGameEvent] = useState({});
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );

  const dispatch = useDispatch();
  const [userBets, setUserBets] = useState();

  var gameSettings_ = useSelector((state) => state.gameData[game + "Settings"]);
  var gameSettings = useMemo(() => gameSettings_, [gameSettings_]);
  if (gameSettings && gameSettings[id]) {
    gameSettings = gameSettings[id];
  }
  const [loading, setIsLoading] = useState(false);
  const userData_ = useSelector((state) => state.account.userData);
  const userData = useMemo(() => userData_, [userData_]);

  useEffect(() => {
    let interval;
    getOdds();
    interval = setInterval(() => getOdds(), 1000);

    return () => clearInterval(interval);
  }, [id]);

  const getGameEventDetails = async () => {
    const { response } = await getCricketEventDetailsAPI(id);
    setGameEvent(response);
  };

  useEffect(() => {
    getGameEventDetails();
  }, [id]);
  useEffect(() => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  }, [game, id]);

  const setSelectedEventForBet = (data) => {
    if (data.isBookmaker) {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: {
          ...data,
          eventId: id,
          amount: 0,
          profit: getProfit(0, game, data).profit,
          loss: getProfit(0, game, data).loss,
        },
      });
    } else {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: {
          ...data,
          eventId: id,
          amount: 0,
          profit: getProfit(0, game, data).profit,
          loss: getProfit(0, game, data).loss,
        },
      });
    }
  };

  const getCricketMatchDetails = useCallback(async () => {
    const { response } = await getMatchDetailsCricketAPI(id);
    dispatch({
      type: "gameData/setMatchSettings",
      payload: {
        game,
        settings: response,
        eventId: id,
      },
    });
  }, []);

  useEffect(() => {
    if (userData.token) {
      getCricketMatchDetails();
      let interval = setInterval(() => {
        getCricketMatchDetails();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, []);
  const fancySetBet = (data, type, market, gameType) => {
    let matchData = oddQuery?.data?.response?.fancyOdds?.find(
      (item) => item?.gameType?.toLowerCase() == gameType?.toLowerCase()
    );

    if (!matchData) return;

    let selectedEventForBet = {
      eventId: id,
      amount: 0,
      marketId: data?.marketId,
      marketType: market,
      marketName: matchData?.marketName,
      type: type?.startsWith("lay") ? "lay" : "back",
      isBookmaker: false,
      selectedRate: data[type?.startsWith("lay") ? "laySize1" : "backSize1"],
      selectedOdd: data[type],
      selectedId: data.selectionId,
      profit: getProfit(0, game, data).profit,
      loss: getProfit(0, game, data).loss,
      amount: null,
      runnerName: data.runnerName,
      isYesAndNo: true,
      isFancy: true,
      gameType: gameType,
    };
    if (selectedEventForBet?.selectedOdd == 0) {
      return;
    }
    setBetModalOpen(true);
    setShowTimer(true);
    setTimerSeconds(5);
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: selectedEventForBet,
    });
  };
  const bookmakerSetBet = (data, type, market, gameType) => {
    let matchData = oddQuery?.data?.response?.bookMakerOdds[0]?.bm1;
    if (!matchData) return;

    let selectedEventForBet = {
      eventId: id,
      amount: 0,
      marketId: matchData?.marketId,
      marketType: market,
      marketName: matchData?.marketName,
      type: type?.startsWith("lay") ? "lay" : "back",
      isBookmaker: true,
      selectedRate: data[type?.startsWith("lay") ? "laySize1" : "backSize1"],
      selectedOdd: data[type],
      selectedId: data.selectionId,
      profit: getProfit(0, game, data).profit,
      loss: getProfit(0, game, data).loss,
      amount: null,
      runnerName: data.runnerName,
      isYesAndNo: false,
    };
    if (selectedEventForBet?.selectedOdd == 0) {
      return;
    }
    setBetModalOpen(true);
    setShowTimer(true);
    setTimerSeconds(5);
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: selectedEventForBet,
    });
  };

  const myBets = useCallback(async () => {
    try {
      const { response, code, extra } = await getMyBetsCricketAPI(
        id,
        userData.token
      );
      if (code == 200) {
        setUserBets(response);
        setFancyPlusMinus(extra?.fancyPlusMinus);
      }
    } catch (err) {
      setUserBets([]);
    }
  }, []);
  const getOdds = async () => {
    const { response, code } = await getCricketOddsByMatchIdAPI(id);
    if (code == 200) {
      setOddQuery({
        data: {
          response: {
            ...response,
          },
        },
      });
      let normalFancy = response?.fancyOdds?.find(
        (item) => item?.gameType?.toLocaleLowerCase() == "normal"
      );

      let numericOdds_ = [];
      let fallOfWicketsOdds_ = [];
      let firstWicketOdds_ = [];
      let otherOdds_ = [];
      let boundariesOdds_ = [];
      let runsOdds_ = [];

      for (let i = 0; i < normalFancy?.oddDetailsDTOS?.length; i++) {
        if (
          checkIfStringIsNumber(
            normalFancy?.oddDetailsDTOS[i]?.runnerName?.split(" ")[0]
          ) ||
          normalFancy?.oddDetailsDTOS[i]?.runnerName
            ?.toLowerCase()
            ?.startsWith("fall of ") ||
          normalFancy?.oddDetailsDTOS[i]?.runnerName
            ?.toLowerCase()
            ?.includes("wkt runs")
        ) {
          if (
            checkIfStringIsNumber(
              normalFancy?.oddDetailsDTOS[i]?.runnerName?.split(" ")[0]
            )
          ) {
            numericOdds_.push(normalFancy?.oddDetailsDTOS[i]);
          }
          if (
            normalFancy?.oddDetailsDTOS[i]?.runnerName
              ?.toLowerCase()
              ?.startsWith("fall of ")
          ) {
            fallOfWicketsOdds_.push(normalFancy?.oddDetailsDTOS[i]);
          }
          if (
            normalFancy?.oddDetailsDTOS[i]?.runnerName
              ?.toLowerCase()
              ?.includes("wkt runs")
          ) {
            firstWicketOdds_.push(normalFancy?.oddDetailsDTOS[i]);
          }
        } else {
          otherOdds_.push(normalFancy?.oddDetailsDTOS[i]);
        }
      }
      numericOdds_ = numericOdds_?.sort((a, b) => {
        return a?.runnerName?.split(" ")[0] - b?.runnerName?.split(" ")[0];
      });
      let matchOverOdds_ = [];
      let partnershipOdds_ = [];
      let totalMatchRuns_ = [];

      for (let i = 0; i < otherOdds_.length; i++) {
        if (otherOdds_[i]?.runnerName?.toLowerCase()?.includes("boundaries")) {
          boundariesOdds_.push(otherOdds_[i]);
          otherOdds_.splice(i, 1);
        }
        if (otherOdds_[i]?.runnerName?.toLowerCase()?.includes("wkt pship")) {
          partnershipOdds_.push(otherOdds_[i]);
          otherOdds_.splice(i, 1);
        }
        if (
          otherOdds_[i]?.runnerName
            ?.toLowerCase()
            ?.includes("match 1st over run")
        ) {
          matchOverOdds_.push(otherOdds_[i]);
          otherOdds_.splice(i, 1);
        }
        if (
          otherOdds_[i]?.runnerName?.toLowerCase()?.includes("total match runs")
        ) {
          totalMatchRuns_.push(otherOdds_[i]);
          otherOdds_.splice(i, 1);
        }
      }
      setNumericOdds([...matchOverOdds_, ...numericOdds_]);
      setFallOfWicketsOdds(fallOfWicketsOdds_);
      setFirstWicketOdds(
        firstWicketOdds_.sort((a, b) => {
          return a?.runnerName?.split(" ")[1] - b?.runnerName?.split(" ")[1];
        })
      );
      setBoundariesOdds([...boundariesOdds_, ...totalMatchRuns_]);
      setOtherOdds([...otherOdds_, ...partnershipOdds_]);
    }
  };
  const checkIfStringIsNumber = (str) => {
    return !isNaN(str) && !isNaN(parseFloat(str));
  };
  useEffect(() => {
    myBets();
    let interval = setInterval(() => {
      myBets();
    }, 5000);
    return () => clearInterval(interval);
  }, [game, id]);

  const placeBet = async () => {
    setIsLoading(true);

    let delay = 1500;
    let selectedAmount_ = selectedEventForBet?.amount;
    if (selectedAmount_ <= 0) {
      toast.error("Please enter a Valid Amount");
      setIsLoading(false);
      return;
    }
    if (selectedEventForBet.selectedOdd <= 0) {
      toast.error("Please select a Valid Odd");
      setIsLoading(false);
      return;
    }
    if (game == "cricket" || game == "tennis" || game == "soccer") {
      if (
        (selectedEventForBet.marketName == "MATCH_ODDS" &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS" &&
          gameSettings?.sessionMinStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
          gameSettings?.sessionMinStack > selectedAmount_)
      ) {
        toast.error(
          `Minimum bet amount is ${
            selectedEventForBet.marketName == "FANCY_ODDS_2"
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == "FANCY_ODDS"
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
              ? gameSettings?.bookmakerMinStack
              : gameSettings?.minStack
          }`
        );
        setIsLoading(false);
        return;
      }
      if (
        (selectedEventForBet.marketName == "MATCH_ODDS" &&
          gameSettings?.maxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
          gameSettings?.bookmakerMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS" &&
          gameSettings?.sessionMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
          gameSettings?.sessionMaxStack < selectedAmount_)
      ) {
        toast.error(
          `Maximum bet amount is ${
            selectedEventForBet.marketName == "FANCY_ODDS_2"
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == "FANCY_ODDS"
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
              ? gameSettings?.bookmakerMaxStack
              : gameSettings?.maxStack
          }`
        );
        setIsLoading(false);
        return;
      }
    }
    if (selectedEventForBet?.isFancy) {
      delay = 1200;
    }
    let profit;
    let loss;
    if (game == "cricket" && selectedEventForBet.isBookmaker) {
      profit = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketBookMakerPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else if (game == "tennis" || game == "soccer") {
      profit = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = casinoPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    } else {
      profit = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).profit;
      loss = cricketMatchOddsPL(
        selectedEventForBet.selectedOdd,
        selectedAmount_,
        selectedEventForBet.type
      ).loss;
    }
    let payload = {
      amount: selectedAmount_,
      profit: parseFloat(profit).toFixed(2),
      loss: parseFloat(loss).toFixed(2),
      marketId: selectedEventForBet.marketId,
      selectionId: selectedEventForBet.selectedId,
      type: selectedEventForBet.type,
      eventId: parseInt(selectedEventForBet.eventId),
      runnerName: selectedEventForBet.runnerName,
      gameType:
        selectedEventForBet.marketName != "MATCH_ODDS" ||
        selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
        selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
          ? selectedEventForBet.gameType
          : selectedEventForBet.marketName,
      marketName: selectedEventForBet.marketName,
    };

    if (selectedEventForBet.isBookmaker && selectedEventForBet?.layRate) {
      payload["layRate"] = selectedEventForBet.selectedOdd;
    } else if (
      selectedEventForBet?.isBookmaker &&
      selectedEventForBet?.backRate
    ) {
      payload["backRate"] = selectedEventForBet.selectedOdd;
    }

    if (selectedEventForBet.type == "back") {
      payload["back"] = parseFloat(selectedEventForBet.selectedOdd);
      payload["backRate"] = parseFloat(selectedEventForBet.selectedOdd);
    } else {
      payload["layRate"] = parseFloat(selectedEventForBet.selectedOdd);
      payload["lay"] = parseFloat(selectedEventForBet.selectedOdd);
    }

    await new Promise((resolve) => setTimeout(resolve, delay));

    if (game == "cricket" && !selectedEventForBet.isFancy) {
      const { response, code, data } = await placeCricketBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        toast.success("Bet Placed");
        setBetModalOpen(false);
      } else if (code == 500) {
        toast.error(response);
      } else if (code == "ERR_BAD_RESPONSE") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("Something Went Wrong");
      }
    } else if (game == "cricket" && selectedEventForBet?.isFancy) {
      let fancyPayload = {
        amount: selectedAmount_,
        eventId: parseInt(selectedEventForBet.eventId),
        marketId: selectedEventForBet.marketId,
        marketName:
          selectedEventForBet.marketName != "MATCH_ODDS" ||
          selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
          selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
            ? selectedEventForBet.gameType
            : selectedEventForBet.marketName,
        gameType: selectedEventForBet.gameType,
      };
      console.log(fancyPayload);

      if (selectedEventForBet.type === "lay") {
        fancyPayload["layRate"] = selectedEventForBet.selectedRate;
        fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
        if (selectedEventForBet.gameType == "fancy1") {
          fancyPayload["layRate"] = selectedEventForBet.selectedOdd;
          fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
        }
      } else {
        fancyPayload["backRate"] = selectedEventForBet.selectedRate;
        fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
        if (selectedEventForBet.gameType == "fancy1") {
          fancyPayload["backRate"] = selectedEventForBet.selectedOdd;
          fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
        }
      }

      if (
        applyFancyFor.find(
          (item) =>
            item?.toLowerCase() == selectedEventForBet?.gameType?.toLowerCase()
        )
      ) {
        if (selectedEventForBet?.type == "lay") {
          if (selectedEventForBet?.gameType == "fancy1") {
            fancyPayload.profit = cricketMatchOddsPL(
              selectedEventForBet.selectedOdd,
              selectedAmount_,
              selectedEventForBet.type
            ).loss;
            fancyPayload.loss = cricketMatchOddsPL(
              selectedEventForBet.selectedOdd,
              selectedAmount_,
              selectedEventForBet.type
            ).profit;
          } else {
            fancyPayload.profit = cricketFancyPL(
              selectedEventForBet.selectedRate,
              selectedAmount_,
              selectedEventForBet.type
            ).loss;
            fancyPayload.loss = cricketFancyPL(
              selectedEventForBet.selectedRate,
              selectedAmount_,
              selectedEventForBet.type
            ).profit;
          }
        } else {
          if (selectedEventForBet?.gameType == "fancy1") {
            fancyPayload.profit = cricketMatchOddsPL(
              selectedEventForBet.selectedOdd,
              selectedAmount_,
              selectedEventForBet.type
            ).profit;
            fancyPayload.loss = cricketMatchOddsPL(
              selectedEventForBet.selectedOdd,
              selectedAmount_,
              selectedEventForBet.type
            ).loss;
          } else {
            fancyPayload.profit = cricketFancyPL(
              selectedEventForBet.selectedRate,
              selectedAmount_,
              selectedEventForBet.type
            ).profit;
            fancyPayload.loss = cricketFancyPL(
              selectedEventForBet.selectedRate,
              selectedAmount_,
              selectedEventForBet.type
            ).loss;
          }
        }
      } else {
        fancyPayload.profit = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).profit;
        fancyPayload.loss = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).loss;
        fancyPayload.selectionId = selectedEventForBet.selectedId;
        fancyPayload.runnerName = selectedEventForBet.runnerName;
        if (selectedEventForBet.type === "lay") {
          fancyPayload["layRate"] = selectedEventForBet.selectedOdd;
        } else {
          fancyPayload["backRate"] = selectedEventForBet.selectedOdd;
        }
      }

      const { response, code, data } = await placeFancyCricketBetAPI(
        fancyPayload,
        userData.token
      );
      if (code == 200) {
        toast.success("Bet Placed successfully");
        setIsLoading(false);
        setTimerSeconds(0);
        setBetModalOpen(false);
        dispatch({
          type: "gameData/setSelectedEventForBet",
          payload: null,
        });
      } else if (code == 500) {
        toast.error(response);
      } else if (code == "ERR_BAD_RESPONSE") {
        setIsLoading(false);
        toast.error("Something Went wrong");
      } else {
        toast.error("Something Went Wrong");
      }
    }
    setIsLoading(false);
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  };
  const naviagte = useNavigate();

  const getBetCount = async () => {
    const { response } = await getBetCountAPI();
    return response;
  };
  const [showScore, setShowScore] = useState(false);

  useEffect(() => {
    let timerInterval;
    if (showTimer) {
      timerInterval = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timerInterval);
    };
  }, [showTimer]);

  useEffect(() => {
    if (timerSeconds === 0) {
      setBetModalOpen(false);
      setShowTimer(false);
      setTimerSeconds(5);
    }
  }, [timerSeconds]);
  const getLiveStreamingUrl = async () => {
    const { response } = await getLiveStreamingUrlAPI(gameEvent?.eventId);
    setChannelId(response);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [gameEvent?.eventId]);
  const pullToSoftRefresh = () => {
    getOdds();
    getBetCount();
  };
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pullHeight, setPullHeight] = useState(0);
  const containerRef = useRef(null);
  const maxPullHeight = 100; // Max height to show the refresh icon

  useEffect(() => {
    const container = containerRef.current;

    let startY = 0;
    let isPulling = false;

    const handleTouchStart = (e) => {
      startY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      const currentY = e.touches[0].clientY;
      const deltaY = currentY - startY;

      if (deltaY > 0 && container.scrollTop === 0) {
        isPulling = true;
        e.preventDefault();
        const height = Math.min(deltaY, maxPullHeight);
        setPullHeight(height);
      }
    };

    const handleTouchEnd = () => {
      if (isPulling && pullHeight >= maxPullHeight) {
        setIsRefreshing(true);
        refreshPage();
      }
      setPullHeight(0);
      isPulling = false;
    };

    const refreshPage = async () => {
      // Simulate refresh (e.g., API call)
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsRefreshing(false);
    };

    container.addEventListener("touchstart", handleTouchStart);
    container.addEventListener("touchmove", handleTouchMove);
    container.addEventListener("touchend", handleTouchEnd);

    return () => {
      container.removeEventListener("touchstart", handleTouchStart);
      container.removeEventListener("touchmove", handleTouchMove);
      container.removeEventListener("touchend", handleTouchEnd);
    };
  }, [pullHeight]);
  const handleRefresh = async () => {
    getOdds();
    toast.success("Odds Refreshed");
  };

  return (
    <div
      ref={containerRef}
      //className="ant-layout-content gx-layout-content   "
    >
      {loading && <Loader />}
      {BetModalopen && !loading && (
        <PlaceBetModal
          isOpen={BetModalopen}
          handleDefaultValueClick={handleDefaultValueClick}
          handlePlaceBet={placeBet}
          onClose={handleBetModalClose}
          timerSeconds={timerSeconds}
          selectedEventForBet={selectedEventForBet}
          setSelectedEventForBet={setSelectedEventForBet}
          isLoading={loading}
        />
      )}
      <div className="gx-main-content-wrapper">
        <div className="ant-row gx-bg-flex gx-align-items-center gx-justify-content-between gx-bg-grey">
          <div>
            <span
              onClick={() => setShowTv(!showTv)}
              className="gx-bg-primary gx-px-3 gx-py-1 gx-font-weight-semi-bold gx-text-white"
            >
              TV
            </span>
            <span className="gx-px-2 ">
              <a
                onClick={() => {
                  handleRefresh();
                  window.location.reload();
                }}
              >
                <span className="gx-px-1 gx-py-1 gx-font-weight-semi-bold gx-text-uppercase gx-text-white">
                  <span
                    role="img"
                    aria-label="refresh"
                    className="anticon anticon-refresh"
                    style={{ fontSize: 18 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      fill="#ffff"
                      height="20px"
                      width="20px"
                      version="1.1"
                      id="Layer_1"
                      viewBox="0 0 383.748 383.748"
                      xmlSpace="preserve"
                    >
                      <g>
                        <path d="M62.772,95.042C90.904,54.899,137.496,30,187.343,30c83.743,0,151.874,68.13,151.874,151.874h30   C369.217,81.588,287.629,0,187.343,0c-35.038,0-69.061,9.989-98.391,28.888C70.368,40.862,54.245,56.032,41.221,73.593   L2.081,34.641v113.365h113.91L62.772,95.042z" />
                        <path d="M381.667,235.742h-113.91l53.219,52.965c-28.132,40.142-74.724,65.042-124.571,65.042   c-83.744,0-151.874-68.13-151.874-151.874h-30c0,100.286,81.588,181.874,181.874,181.874c35.038,0,69.062-9.989,98.391-28.888   c18.584-11.975,34.707-27.145,47.731-44.706l39.139,38.952V235.742z" />
                      </g>
                    </svg>
                  </span>
                </span>
              </a>
            </span>
          </div>

          <div className="gx-py-1">
            <span className="">
              <button
                type="button"
                role="switch"
                aria-checked="true"
                className="ant-switch ant-switch-small gx-mx-3 ant-switch-checked"
                style={{
                  backgroundColor: "green",
                  transform: "scale(1.3)",
                }}
              >
                <div className="ant-switch-handle" />
                <span className="ant-switch-inner">ON</span>
              </button>
            </span>
            <span
              onClick={() => {
                getOdds();
                toast.success("Odds Refreshed");
              }}
              className="gx-bg-primary gx-px-3 gx-py-1 gx-font-weight-semi-bold gx-text-white"
            >
              FS
            </span>
          </div>
        </div>
        {showTv && (
          <iframe
            style={{
              width: "100%",
              height: "220px",
            }}
            src={`https://video.proexch.in/tv/static?chid=${channelId}`}
          />
        )}
        <div className="ant-row" />
        <div className="ant-row ant-row-center gx-bg-flex gx-align-items-center gx-justify-content-between" />
        <div className="ant-row ant-row-center">
          <div className="ant-col gx-col-full ant-col-xs-24 ant-col-sm-24">
            <div className="ant-row" style={{ height: 110 }}>
              <iframe
                src={`https://score.crickexpo.in/socket-iframe-1/${
                  gameEvent?.eventId
                }`}
                title="Score-I-frame"
                className=""
                style={{ width: "100%", height: "100%", border: "none" }}
              />
            </div>
          </div>

          {oddQuery?.data?.response &&
            oddQuery?.data?.response?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS
              ?.length > 0 && (
              <div className="ant-col gx-px-0 gx-py-0 gx-mx-0 gx-my-0 ant-col-xs-24 ant-col-sm-24">
                <div
                  className="ant-table-wrapper gx-w-100 custom-ant-table gx-mx-0 gx-my-0"
                  style={{ marginTop: 16 }}
                >
                  <div className="ant-spin-nested-loading">
                    <div className="ant-spin-container">
                      <div className="ant-table ant-table-small ant-table-bordered ant-table-scroll-horizontal">
                        <div className="ant-table-container">
                          <div
                            className="ant-table-content"
                            style={{ overflow: "auto hidden" }}
                          >
                            <table
                              style={{
                                width: "auto",
                                minWidth: "100%",
                                tableLayout: "auto",
                              }}
                            >
                              <colgroup>
                                <col style={{ width: "60%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "20%" }} />
                              </colgroup>
                              <thead className="ant-table-thead">
                                <tr>
                                  <th className="ant-table-cell">
                                    <div
                                      className="gx-bg-flex gx-justify-content-between minMax"
                                      style={{ display: "flex" }}
                                    >
                                      <span className="gx-d-none gx-d-lg-block">
                                        Bookmaker
                                      </span>
                                      <span style={{ textWrap: "nowrap" }}>
                                        Min: {gameSettings?.bookmakerMinStack}{" "}
                                        Max: {gameSettings?.bookmakerMaxStack}
                                      </span>
                                    </div>
                                  </th>
                                  <th
                                    className="ant-table-cell"
                                    style={{ textAlign: "center" }}
                                  >
                                    Lagai
                                  </th>
                                  <th
                                    className="ant-table-cell"
                                    style={{ textAlign: "center" }}
                                  >
                                    Khai
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                                <tr
                                  aria-hidden="true"
                                  className="ant-table-measure-row"
                                  style={{ height: 0, fontSize: 0 }}
                                >
                                  <td
                                    style={{
                                      padding: 0,
                                      border: 0,
                                      height: 0,
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 0,
                                        overflow: "hidden",
                                      }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      padding: 0,
                                      border: 0,
                                      height: 0,
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 0,
                                        overflow: "hidden",
                                      }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      padding: 0,
                                      border: 0,
                                      height: 0,
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 0,
                                        overflow: "hidden",
                                      }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                </tr>

                                {oddQuery?.data?.response?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS.map(
                                  (match, index) => (
                                    <>
                                      <tr
                                        data-row-key={0}
                                        className="ant-table-row ant-table-row-level-0 no-hover"
                                      >
                                        <td className="ant-table-cell">
                                          <div className="gx-bg-flex gx-">
                                            <div className=" gx-font-weight-semi-bold gx-text-uppercase">
                                              {match?.runnerName}
                                            </div>
                                            <div
                                              style={{
                                                color:
                                                  gameSettings?.plExposureResponseDTOs
                                                    ?.find(
                                                      (item) =>
                                                        item.marketIdExternal ==
                                                        oddQuery?.data
                                                          ?.response
                                                          ?.bookMakerOdds[0]
                                                          ?.bm1?.marketId
                                                    )
                                                    ?.runnerPlExposureDTOS?.find(
                                                      (pl) =>
                                                        pl.selectionId ==
                                                        match?.selectionId
                                                    )?.exposure > 0
                                                    ? "green"
                                                    : "red",
                                              }}
                                              className="gx-font-weight-semi-bold gx-text-light-black "
                                            >
                                              {
                                                gameSettings?.plExposureResponseDTOs
                                                  ?.find(
                                                    (item) =>
                                                      item.marketIdExternal ==
                                                      oddQuery?.data?.response
                                                        ?.bookMakerOdds[0]
                                                        ?.bm1?.marketId
                                                  )
                                                  ?.runnerPlExposureDTOS?.find(
                                                    (pl) =>
                                                      pl.selectionId ==
                                                      match?.selectionId
                                                  )?.exposure
                                              }
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          onClick={() => {
                                            bookmakerSetBet(
                                              match,
                                              "back1",
                                              match?.marketName
                                            );
                                          }}
                                          className="ant-table-cell"
                                          style={{ textAlign: "center" }}
                                        >
                                          <div className="gx-font-weight-semi-bold gx-text-blue gx-fs-lg gx-text-uppercase">
                                            {parseInt(match?.back1)}
                                          </div>
                                        </td>
                                        <td
                                          onClick={() => {
                                            bookmakerSetBet(
                                              match,
                                              "lay1",
                                              match?.marketName
                                            );
                                          }}
                                          className="ant-table-cell"
                                          style={{ textAlign: "center" }}
                                        >
                                          <div
                                            className="gx-font-weight-semi-bold  gx-fs-lg gx-text-capitalize"
                                            style={{
                                              color: "rgb(227, 68, 103)",
                                            }}
                                          >
                                            {parseInt(match?.lay1)}
                                          </div>
                                        </td>
                                      </tr>
                                      {match?.remark && (
                                        <tr className="odd-remark">
                                          <marquee
                                            style={{
                                              width: "80vw",
                                            }}
                                          >
                                            {match?.remark}
                                          </marquee>
                                        </tr>
                                      )}
                                    </>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="ant-col gx-px-0 gx-py-0 gx-mx-0 gx-my-0 ant-col-xs-24 ant-col-sm-24">
            <div
              className="ant-table-wrapper gx-w-100 gx-mx-0 gx-my-0 gx-table-responsive"
              style={{ marginTop: 16 }}
            >
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-small ant-table-bordered">
                    <div className="ant-table-container">
                      <div className="ant-table-content">
                        {oddQuery?.data?.response?.fancyOdds &&
                          oddQuery?.data?.response?.fancyOdds?.length > 0 &&
                          oddQuery?.data?.response?.fancyOdds?.map((item) => (
                            <table style={{ tableLayout: "auto" }}>
                              <colgroup>
                                <col className="ant-table-expand-icon-col" />
                                <col style={{ width: "60%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "20%" }} />
                              </colgroup>
                              <thead className="ant-table-thead">
                                <tr>
                                  <th className="ant-table-cell ant-table-row-expand-icon-cell" />
                                  <th className="ant-table-cell">
                                    <div
                                      className="gx-bg-flex gx-justify-content-between minMax"
                                      style={{ display: "flex" }}
                                    >
                                      <span>{item?.gameType}</span>
                                      <span style={{ textWrap: "nowrap" }}>
                                        Min: {gameSettings?.sessionMinStack}{" "}
                                        Max: {gameSettings?.sessionMaxStack}
                                      </span>
                                    </div>
                                  </th>
                                  <th
                                    className="ant-table-cell"
                                    style={{ textAlign: "center" }}
                                  >
                                    No
                                  </th>
                                  <th
                                    className="ant-table-cell"
                                    style={{ textAlign: "center" }}
                                  >
                                    YES
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                                {item?.oddDetailsDTOS?.map((match) => (
                                  <>
                                    <tr
                                      data-row-key="cfb9620c887bce260952682b91d7a0cfed33ab1a"
                                      className="ant-table-row ant-table-row-level-0"
                                    >
                                      <td className="ant-table-cell ant-table-row-expand-icon-cell">
                                        <button
                                          type="button"
                                          className="ant-table-row-expand-icon ant-table-row-expand-icon-spaced"
                                          aria-label="Expand row"
                                          aria-expanded="false"
                                        />
                                      </td>
                                      <td className="ant-table-cell">
                                        <div className=" gx-my-0 gx-bg-flex gx-flex-column">
                                          <div className="">
                                            <div className="gx-font-weight-semi-bold">
                                              {match?.runnerName}
                                            </div>
                                            <div className="gx-font-weight-semi-bold gx-fs-sm">
                                              session Limit :{" "}
                                              {match?.maxLimit}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        className="ant-table-cell matchdtailsSuspendBackground"
                                        style={{ textAlign: "center" }}
                                      >
                                        <div
                                          onClick={() =>
                                            fancySetBet(
                                              match,
                                              "lay1",
                                              "fancyOdds",
                                              item?.gameType
                                            )
                                          }
                                        >
                                          <div className="gx-font-weight-semi-bold gx-fs-lg gx-text-red">
                                            {parseInt(match?.lay1)}
                                          </div>
                                          <div className="gx-font-weight-semi-bold gx-fs-xs gx-text-red">
                                            {match?.laySize1}
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        className="ant-table-cell matchdtailsSuspendBackground"
                                        style={{ textAlign: "center" }}
                                      >
                                        <div
                                          onClick={() =>
                                            fancySetBet(
                                              match,
                                              "back1",
                                              "fancyOdds",
                                              item?.gameType
                                            )
                                          }
                                        >
                                          <div className="gx-font-weight-semi-bold gx-fs-lg gx-text-blue">
                                            {parseInt(match?.back1)}
                                          </div>
                                          <div className="gx-font-weight-semi-bold gx-fs-xs gx-text-blue">
                                            {match?.backSize1}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    {match?.remark && (
                                      <tr className="odd-remark">
                                        <marquee
                                          style={{
                                            width: "80vw",
                                          }}
                                        >
                                          {match?.remark}
                                        </marquee>
                                      </tr>
                                    )}
                                  </>
                                ))}
                              </tbody>
                            </table>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="gx-mb-2" />
        {userBets?.filter((item) => item?.marketName == "BOOKMAKER_ODDS_1")
          ?.length > 0 && (
          <div className="ant-row ant-row-center">
            <div className="ant-col gx-px-0 gx-py-0 gx-mx-0 gx-my-0 ant-col-xs-24 ant-col-sm-24">
              <div className="gx-bg-flex gx-justify-content-center gx-bg-grey gx-fs-lg gx-font-weight-semi-bold gx-text-white gx-py-1">
                BOOKMAKER BETS
              </div>
              <div className="ant-table-wrapper gx-w-100 gx-mx-0 gx-my-0">
                <div className="ant-spin-nested-loading">
                  <div className="ant-spin-container">
                    <div className="ant-table ant-table-small ant-table-bordered ant-table-ping-right ant-table-scroll-horizontal">
                      <div className="ant-table-container">
                        <div
                          className="ant-table-content"
                          style={{ overflow: "auto hidden" }}
                        >
                          <table
                            style={{
                              width: "auto",
                              minWidth: "100%",
                              tableLayout: "auto",
                            }}
                          >
                            <colgroup />
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">RUNNER</th>
                                <th className="ant-table-cell">AMOUNT</th>
                                <th className="ant-table-cell">RUN</th>
                                <th className="ant-table-cell">RATE</th>
                                <th className="ant-table-cell">MODE</th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              <tr
                                aria-hidden="true"
                                className="ant-table-measure-row"
                                style={{ height: 0, fontSize: 0 }}
                              >
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                              </tr>
                              {userBets?.length > 0 &&
                                userBets
                                  ?.filter(
                                    (item) =>
                                      item?.marketName ==
                                        "BOOKMAKER_ODDS_1" &&
                                      (item?.result == "" ||
                                        item?.result == null)
                                  )
                                  ?.map((bet, index) => (
                                    <tr
                                      data-row-key={0}
                                      className={`ant-table-row ant-table-row-level-0 matchdtails${
                                        bet?.back ? "Yes" : "No"
                                      }Background`}
                                    >
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.runnerName}
                                        </span>
                                      </td>
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.amount}
                                        </span>
                                      </td>
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.lay ? bet?.lay : bet.back}
                                        </span>
                                      </td>
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.backRate
                                            ? bet?.backRate
                                            : bet?.layRate}
                                        </span>
                                      </td>
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.back ? "LAGAI" : "KHAI"}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {userBets?.filter(
          (item) =>
            item?.marketName != "BOOKMAKER_ODDS_1" && item?.result == null
        )?.length > 0 && (
          <div className="ant-row ant-row-center">
            <div className="ant-col gx-px-0 gx-py-0 gx-mx-0 gx-my-0 ant-col-xs-24 ant-col-sm-24">
              <div className="gx-bg-flex gx-justify-content-center gx-bg-grey gx-fs-lg gx-font-weight-semi-bold gx-text-white gx-py-1">
                FANCY BETS
              </div>
              <div className="ant-table-wrapper gx-w-100 gx-mx-0 gx-my-0">
                <div className="ant-spin-nested-loading">
                  <div className="ant-spin-container">
                    <div className="ant-table ant-table-small ant-table-bordered ant-table-ping-right ant-table-scroll-horizontal">
                      <div className="ant-table-container">
                        <div
                          className="ant-table-content"
                          style={{ overflow: "auto hidden" }}
                        >
                          <table
                            style={{
                              width: "auto",
                              minWidth: "100%",
                              tableLayout: "auto",
                            }}
                          >
                            <colgroup />
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">RUNNER</th>
                                <th className="ant-table-cell">AMOUNT</th>
                                <th className="ant-table-cell">RUN</th>
                                <th className="ant-table-cell">RATE</th>
                                <th className="ant-table-cell">MODE</th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              <tr
                                aria-hidden="true"
                                className="ant-table-measure-row"
                                style={{ height: 0, fontSize: 0 }}
                              >
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                                <td
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    height: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 0,
                                      overflow: "hidden",
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </td>
                              </tr>
                              {userBets?.length > 0 &&
                                userBets
                                  ?.filter(
                                    (item) =>
                                      item?.marketName !=
                                        "BOOKMAKER_ODDS_1" &&
                                      (item?.result == "" ||
                                        item?.result == null)
                                  )
                                  ?.map((bet, index) => (
                                    <tr
                                      data-row-key={0}
                                      className={`ant-table-row ant-table-row-level-0 matchdtails${
                                        bet?.back ? "Yes" : "No"
                                      }Background`}
                                    >
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.runnerName}
                                        </span>
                                      </td>
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.amount}
                                        </span>
                                      </td>
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.lay ? bet?.lay : bet.back}
                                        </span>
                                      </td>
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.backRate
                                            ? bet?.backRate
                                            : bet?.layRate}
                                        </span>
                                      </td>
                                      <td className="ant-table-cell">
                                        <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                          {bet?.back ? "YES" : "NO"}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showCompleteBets && (
          <CompleteBetModal
            setShow={setShowCompleteBets}
            show={showCompleteBets}
            fancyPlusMinus={fancyPlusMinus}
            data={userBets?.filter(
              (item) =>
                item?.marketName != "BOOKMAKER_ODDS_1" && item?.result != null
            )}
          />
        )}

        <div className="ant-row ant-row-center">
          <div className="ant-col gx-px-0 gx-py-2 gx-my-1 gx-justify-content-center">
            <button
              type="button"
              onClick={() => setShowCompleteBets(true)}
              className="ant-btn ant-btn-default gx-bg-grey gx-text-white gx-text-uppercase gx-font-weight-semi-bold"
            >
              <span>Completed Bets</span>
            </button>
          </div>
        </div>
        <div className="ant-row ant-row-center">
          <div className="ant-col gx-px-0 gx-py-2 gx-my-1 gx-justify-content-center">
            {showMatches && (
              <AllMatchModal show={showMatches} setShow={setShowMatches} />
            )}
            <button
              onClick={() => setShowMatches(true)}
              type="button"
              className="ant-btn ant-btn-default gx-my-0  gx-bg-grey gx-text-white gx-text-uppercase gx-font-weight-semi-bold "
            >
              <span>All Matches</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
