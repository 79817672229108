import React from "react";
import "./carddrawer.css";
export default function CardDrawer({ cards }) {
  const [refs, setRefs] = React.useState([
    React.createRef(),
    React.createRef(),
  ]);
  const handleScroll = (index, direction) => {
    const ref = refs[index];
    if (direction === "left") {
      ref.current.scrollLeft -= 100;
    } else {
      ref.current.scrollLeft += 100;
    }
  };
  return (
    <div className="showcards-container ng-tns-c156-2">
      <div className="card-labels">
        <span className="" id="style-Qwzza">
          A
        </span>
        <span className="" id="style-Qwzza">
          B
        </span>
      </div>

      <div className="single-card-d">
        <img
          width={28}
          className="img-fluid me-2 ng-tns-c156-2 ng-trigger ng-trigger-flipState style-4PDyE"
          src={`https://1exch.net/assets/imgages/cardLive/${cards[0]}.png`}
          id="style-4PDyE"
        />
      </div>

      <div className="showcards-wrapper ng-tns-c156-2 ng-star-inserted">
        <div className="card-d-wrapper">
          <i
            onClick={() => handleScroll(0, "left")}
            className="fa fa-arrow-left arrow-icon ng-tns-c156-2"
          />
          <div ref={refs[0]} className="card-wrapper">
            {cards[1]?.map((item) => (
              <img
                width={28}
                className="img-fluid me-2 ng-tns-c156-2 ng-trigger ng-trigger-flipState style-4PDyE"
                src={`https://1exch.net/assets/imgages/cardLive/${item}.png`}
                id="style-4PDyE"
              />
            ))}
          </div>
          <i
            onClick={() => handleScroll(0, "right")}
            className="fa fa-arrow-right arrow-icon ng-tns-c156-2"
          />
        </div>

        <div className="card-d-wrapper">
          <i
            onClick={() => handleScroll(1, "left")}
            className="fa fa-arrow-left arrow-icon ng-tns-c156-2"
          />
          <div ref={refs[1]} className="card-wrapper">
            {cards[2]?.map((item) => (
              <img
                width={28}
                className="img-fluid me-2 ng-tns-c156-2 ng-trigger ng-trigger-flipState style-4PDyE"
                src={`https://1exch.net/assets/imgages/cardLive/${item}.png`}
                id="style-4PDyE"
              />
            ))}
          </div>
          <i
            onClick={() => handleScroll(1, "right")}
            className="fa fa-arrow-right arrow-icon ng-tns-c156-2"
          />
        </div>
      </div>
    </div>
  );
}
