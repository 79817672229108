import { AXIOS_INSTANCE } from "..";
import { CRICKET_SERVICE_URL, getToken } from "../../utils/constants";

export const getAllCricketMatchListAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/all-matches/list`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const placeCricketBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CRICKET_SERVICE_URL}/bet/place`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const placeFancyCricketBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CRICKET_SERVICE_URL}/bet/fancy-place`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getMyBetsCricketAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/bet/my-bet/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatchDetailsCricketAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/match-details`,
      {
        params: {
          eventId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const deleteCricketBetAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_SERVICE_URL}/bet/bets/${id}/status?betStatus=DELETED`,
      {}
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCricketEventDetailsAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/event-data/${eventId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCricketOddsByMatchIdAPI = async (matchId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/odds/${matchId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllMatchesOddsCricketAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/all-matches-dashboard`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getBetCountAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/bet/bet-count`
    );
    return data;
  } catch (error) {
    return error;
  }
};