import React, { useEffect } from "react";
import Header from "./components/Header";

import { Link, useNavigate } from "react-router-dom";
import SvgComponent from "./components/SvgComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMatchesOddsSoccerAPI,
  getAllMatchesOddsTennisAPI,
} from "../service/soccer&tennis";
import {
  getAllMatchesOddsCricketAPI,
  getBetCountAPI,
} from "../service/cricket";
import moment from "moment";

const Matches = () => {
  const dispatch = useDispatch();
  const [selectedGame, setSelectedGame] = React.useState("cricket");
  const data = useSelector((state) => state.gameData[selectedGame + "Games"]);
  const userData = useSelector((state) => state.account.userData);

  const naviagte = useNavigate();
  const calculateTime = (time) => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let matchDate = new Date(time);
    let payload = {
      string: "Today",
      hours: matchDate.getHours(),
      minutes: matchDate.getMinutes(),
    };
    if (matchDate.getDate() == today.getDate()) {
      payload = {
        string: "Today",
        hours: matchDate.getHours(),
        minutes: matchDate.getMinutes(),
      };
    }
    if (matchDate.getDate() == yesterday.getDate()) {
      payload = {
        string: "Yesterday",
        hours: matchDate.getHours(),
        minutes: matchDate.getMinutes(),
      };
    }
    if (matchDate.getDate() == tomorrow.getDate()) {
      payload = {
        string: "Tomorrow",
        hours: matchDate.getHours(),
        minutes: matchDate.getMinutes(),
      };
    }
    return payload;
  };
  const getSoccerMatches = async () => {
    const { response, code } = await getAllMatchesOddsSoccerAPI();
    if (code == 200) {
      dispatch({
        type: "gameData/setSoccerGames",
        payload: response,
      });
    }
  };
  const getTennisMatches = async () => {
    const { response, code } = await getAllMatchesOddsTennisAPI();
    if (code == 200) {
      dispatch({
        type: "gameData/setTennisGames",
        payload: response,
      });
    }
  };
  const getCricketMatches = async () => {
    const { response, code } = await getAllMatchesOddsCricketAPI();
    if (code == 200) {
      dispatch({
        type: "gameData/setCricketGames",
        payload: response,
      });
    }
  };
  const monthNameMapping = {
    "01": "Januaray",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",
  };
  useEffect(() => {
    let interval;
    if (selectedGame == "cricket") {
      getCricketMatches();
      interval = setInterval(() => {
        getCricketMatches();
      }, 5000);
    } else if (selectedGame == "soccer") {
      getSoccerMatches();
      interval = setInterval(() => {
        getSoccerMatches();
      }, 5000);
    } else if (selectedGame == "tennis") {
      getTennisMatches();
      interval = setInterval(() => {
        getTennisMatches();
      }, 5000);
    } else {
      getSoccerMatches();
      getCricketMatches();
      getTennisMatches();
      interval = setInterval(() => {
        getSoccerMatches();
        getCricketMatches();
        getTennisMatches();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedGame]);
  const navigateToGame = (gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

    if (selectedGame == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    } else {
      naviagte(`/game/${selectedGame}/${gameEvent[selectedGame + "Id"]}`, {
        state: { gameEvent },
      });
    }
  };
  return (
    <>
      <div className="position-relative">
        <main className="warp">
          <ul className="games-types-top">
            <li
              onClick={() => setSelectedGame("cricket")}
              className={`cricket-button ${
                selectedGame == "cricket" ? "active" : ""
              }`}
            >
              <i class="far fa-cricket fa-fw"></i>
            </li>
            <li
              onClick={() => setSelectedGame("soccer")}
              className={`Football ${selectedGame == "soccer" ? "active" : ""}`}
            >
              <i class="fal fa-futbol"></i>
            </li>
            <li
              onClick={() => setSelectedGame("tennis")}
              className={`Tennis ${selectedGame == "tennis" ? "active" : ""}`}
            >
              <i class="far fa-tennis-ball"></i>
            </li>
            <li
              onClick={() => setSelectedGame("casino")}
              className={`Casino ${selectedGame == "casino" ? "active" : ""}`}
            >
              <i class="fas fa-spade"></i>
            </li>
            <li
              onClick={() => setSelectedGame("matka")}
              className={`Matka ${selectedGame == "matka" ? "active" : ""}`}
            >
              <i class="fas fa-cauldron"></i>
            </li>
          </ul>

          <div>
            {selectedGame == "cricket" ||
            selectedGame === "soccer" ||
            selectedGame == "tennis"
              ? data
                  ?.filter((item) => !item?.inPlay)
                  .map((gameEvent) => (
                    <div
                      onClick={() => navigateToGame(gameEvent)}
                      className="list-matches"
                    >
                      <div className="">
                        {moment(
                          gameEvent.eventTime
                            ? gameEvent.eventTime
                            : gameEvent.eventDate
                        ).format("DD MMM hh:mm A")}

                        <h6>
                          {selectedGame == "cricket" ? (
                            <i class="far fa-cricket fa-fw blink"></i>
                          ) : selectedGame == "soccer" ? (
                            <i class="far fa-futbol fa-fw blink"></i>
                          ) : selectedGame == "tennis" ? (
                            <i class="far fa-tennis-ball fa-fw blink"></i>
                          ) : null}
                          <span className="d-inline-flex align-items-center float-left mx-2">
                            <div className="blink"></div>
                          </span>
                          {gameEvent.eventName}
                        </h6>
                        <h5>
                          {moment(
                            gameEvent.eventTime
                              ? gameEvent.eventTime
                              : gameEvent.eventDate
                          ).format("DD MMM hh:mm A")}
                        </h5>
                        <div className="match-info">
                          <p>Match Bets : 0</p>
                          <p>Session Bets : 0</p>
                        </div>
                      </div>
                    </div>
                  ))
              : null}
          </div>

          {selectedGame == "casino" ? (
            <>
              <div className="container-fluid" style={{ padding: "20px 27px" }}>
                <div className="row">
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{ marginBottom: 5, paddingLeft: 0, paddingRight: 5 }}
                  >
                    <Link to="/casinoAddaPage">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/ab20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Andar Bahar
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{ marginBottom: 5, paddingLeft: 0, paddingRight: 5 }}
                  >
                    <Link to="/TeenPattiOneDay">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/teen.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Teen Patti OneDay
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{ marginBottom: 5, paddingLeft: 0, paddingRight: 5 }}
                  >
                    <Link to="/casinoAddaPage">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/teen20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Teen Patti T20
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{ marginBottom: 5, paddingLeft: 0, paddingRight: 5 }}
                  >
                    <Link to="/casinoAddaPage">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/card32.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          32Card A
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{ marginBottom: 5, paddingLeft: 0, paddingRight: 5 }}
                  >
                    <Link to="/casinoAddaPage">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/lucky7.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Lucky 7A
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{ marginBottom: 5, paddingLeft: 0, paddingRight: 5 }}
                  >
                    <Link to="/casinoAddaPage">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/dt20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Dragon Tiger T20
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{ marginBottom: 5, paddingLeft: 0, paddingRight: 5 }}
                  >
                    <Link to="/casinoAddaPage">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/aaa.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Amar Akbar Anthony
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </main>
      </div>
    </>
  );
};

export default Matches;
