import React from "react";

export default function Games() {
  return (
    <main className="ant-layout-content gx-layout-content   ">
      <div className="gx-main-content-wrapper" style={{ paddingBottom: 120 }}>
        <div className="ant-card ant-card-bordered gx-bg-transparent">
          <div className="ant-card-body">
            {/*   <article className="ant-typography gx-bg-grey gx-text-white gx-mb-3 gx-text-center gx-font-weight-semi-bold gx-fs-lg gx-py-2">
              Virtual Casino
            </article>
            <div
              className="ant-row ant-row-center  gx-bg-flax  gx-block gx-justify-center gx-items-center gx-lg:space-x-3 lg:space-y-0 gx-space-x-0 space-y-3 gx-md:px-10"
              style={{ gap: 10 }}
            >
              <div className="ant-col ant-col-10 gx-px-0  gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="#">
                  <div className="ant-card ant-card-bordered ant-card-hoverable">
                    <div className="ant-card-cover">
                      <img
                        alt="dus-ka-dam"
                        src="https://tvspro99.com/assets/images/casino-images/dus-ka-dam.jpeg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body" />
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0  gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="#">
                  <div className="ant-card ant-card-bordered ant-card-hoverable">
                    <div className="ant-card-cover">
                      <img
                        alt="roultee"
                        src="https://tvspro99.com/assets/images/casino-images/roultee.jpeg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body" />
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0  gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="#">
                  <div className="ant-card ant-card-bordered ant-card-hoverable">
                    <div className="ant-card-cover">
                      <img
                        alt="andar-bahar"
                        src="https://tvspro99.com/assets/images/casino-images/andar-bahar.jpeg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body" />
                  </div>
                </a>
              </div>
            </div> */}
            <article className="ant-typography gx-bg-grey gx-text-white gx-mb-3 gx-text-center gx-font-weight-semi-bold gx-fs-lg gx-py-2">
              Live Casino
            </article>
            <div
              className="ant-row ant-row-center  gx-bg-flax gx-block gx-justify-center gx-items-center gx-lg:space-x-3 lg:space-y-0 gx-space-x-0 space-y-3 gx-md:px-10"
              style={{ gap: 10 }}
            >
              {" "}
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/ander-bahar">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="amar-akbar-anthony"
                        src="https://dataobj.ecoassetsservice.com/casino-icons/lc/abj.jpg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body" />
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/dt20/3035">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="dragon-tiger"
                        src="https://tvspro99.com/assets/images/casino-images/dt-20.jpg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body" />
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/teen9/3048">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="teen9"
                        src="https://tvspro99.com/assets/images/casino-images/rg_teen_patti.jpg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body" />
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/lucky7/3032">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="lucky-7"
                        src="https://tvspro99.com/assets/images/casino-images/Lucky7-A.jpg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body" />
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/teen20/3030">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="teenpatti-20"
                        src="https://tvspro99.com/assets/images/casino-images/Teenpatti%20T20.webp"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body">
                      <div
                        className="newBlinking gx-d-flex gx-justify-content-center gx-align-items-center gx-position-absolute gx-top-0 gx-right-0 flex justify-center items-center top-0"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          display: "block",
                        }}
                      >
                        <span className="gx-text-white gx-fs-xxs gx-text-center gx-font-weight-bold">
                          Coming Soon
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>{" "}
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/teen/3031">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="teen-patti oneday"
                        src="https://tvspro99.com/assets/images/casino-images/1-Day-Teen-Patti.jpg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body">
                      <div
                        className="newBlinking gx-d-flex gx-justify-content-center gx-align-items-center gx-position-absolute gx-top-0 gx-right-0 flex justify-center items-center top-0"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          display: "block",
                        }}
                      >
                        <span className="gx-text-white gx-fs-xxs gx-text-center gx-font-weight-bold">
                          Coming Soon
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/worli2/3054">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="Live-Instant-Worli"
                        src="https://tvspro99.com/assets/images/casino-images/worli-matka-tvs.jpeg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body">
                      <div
                        className="newBlinking gx-d-flex gx-justify-content-center gx-align-items-center gx-position-absolute gx-top-0 gx-right-0 flex justify-center items-center top-0"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          display: "block",
                        }}
                      >
                        <span className="gx-text-white gx-fs-xxs gx-text-center gx-font-weight-bold">
                          Coming Soon
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/dt202/3059">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="Dragen-Tiger-202"
                        src="	https://tvspro99.com/assets/images/casino-images/rg_dragon_tiger.jpg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body">
                      <div
                        className="newBlinking gx-d-flex gx-justify-content-center gx-align-items-center gx-position-absolute gx-top-0 gx-right-0 flex justify-center items-center top-0"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          display: "block",
                        }}
                      >
                        <span className="gx-text-white gx-fs-xxs gx-text-center gx-font-weight-bold">
                          Coming Soon
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/card32-a/3055">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="card32-a"
                        src="	https://tvspro99.com/assets/images/casino-images/32cards.png"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body">
                      <div
                        className="newBlinking gx-d-flex gx-justify-content-center gx-align-items-center gx-position-absolute gx-top-0 gx-right-0 flex justify-center items-center top-0"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          display: "block",
                        }}
                      >
                        <span className="gx-text-white gx-fs-xxs gx-text-center gx-font-weight-bold">
                          Coming Soon
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/card32eu/3034">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="32card"
                        src="https://tvspro99.com/assets/images/casino-images/32-Cards-B.jpg"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body">
                      <div
                        className="newBlinking gx-d-flex gx-justify-content-center gx-align-items-center gx-position-absolute gx-top-0 gx-right-0 flex justify-center items-center top-0"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          display: "block",
                        }}
                      >
                        <span className="gx-text-white gx-fs-xxs gx-text-center gx-font-weight-bold">
                          Coming Soon
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="ant-col ant-col-10 gx-px-0 gx-mb-0 ant-col-md-6 ant-col-lg-3">
                <a href="/main/card32eu/3034">
                  <div className="ant-card ant-card-bordered ant-card-hoverable gx-position-relative">
                    <div className="ant-card-cover">
                      <img
                        alt="32card"
                        src="https://www.androidp1.com/uploads/posts/2023-07/aviator.webp"
                        className="gx-rounded-base gx-pointer gx-object-contain"
                        style={{ height: "11rem" }}
                      />
                    </div>
                    <div className="ant-card-body">
                      <div
                        className="newBlinking gx-d-flex gx-justify-content-center gx-align-items-center gx-position-absolute gx-top-0 gx-right-0 flex justify-center items-center top-0"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          display: "block",
                        }}
                      >
                        <span className="gx-text-white gx-fs-xxs gx-text-center gx-font-weight-bold">
                          Coming Soon
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
